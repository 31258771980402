/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.paragraph {
    margin-top: 1em;
    margin-bottom: 1em;
}

.ew {
    color: #7a5cf0;
}

.ew-back {
    background-color: #7a5cf0;
    color: #ffffff;
}

.grow {
    animation: grow 1s;
    animation-delay: 1s;
    animation-fill-mode: none;
    animation-iteration-count: 2;
    animation-direction: alternate;
}

@keyframes grow {
    from {
        font-size: 2.8em;
    }

    to {
        font-size: 5em;
    }
}

/* Source: https://www.selbekk.io/blog/2019/08/how-to-fade-in-content-as-it-scrolls-into-view/ */
.fade-in-section {
    opacity: 0;
    /*transform: translateY(20vh);*/
    visibility: hidden;
    transition: opacity 1.4s ease-out;
    will-change: opacity, visibility;
}

.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}