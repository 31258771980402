.ck-editor {
    width: 100% !important;
    max-height: 100%;
    display : flex;
    flex-direction: column;
}

    .ck-editor__main {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

        .ck-editor__editable_inline {
            flex-grow:1;
        }

.ck.ck-balloon-panel {
    z-index: 10001 !important;
}